<template>
    <div>
        <header-component></header-component>
        <div class="wallet" v-if="!walletStatus">
            <h2>Connect your Waves wallet</h2>
            <button @click="connect = true">Connect wallet</button>
        </div>
        <profile v-else :address="wallet.address"></profile>
        <div class="main" v-if="nfts.length > 0">
            <sort :nfts="nfts"></sort>
            <div class="nfts">
                <NFT
                    :nft="nft"
                    :viewInfo="true"
                    :url="url"
                    v-for="(nft) in nfts"
                    v-bind:key="nft.assetId"
                ></NFT>
            </div>
        </div>
        <div class="empty" v-else-if="walletStatus && punks.length <= 0">
            <p>?</p>
            <p>You don't have WavesPunks yet, your WavesPunks will be show a moment after mint</p>
        </div>
        <connect-wallet
            :connect="connect"
            v-if="connect"
            v-on:close="connect = $event"
            v-on:success="getMyPunks($event)"
        ></connect-wallet>
    </div>
</template>

<script>
import axios from "axios";

import { sortLowestPrice } from "wavesmarketplace-app/src/helpers/sort";

import ConnectWallet from "wavesmarketplace-app/src/components/ConnectWallet.vue";
import Profile from "wavesmarketplace-app/src/components/Profile.vue";
import Sort from "wavesmarketplace-app/src/components/Sort.vue";
import NFT from "wavesmarketplace-app/src/components/NFT.vue";

import HeaderComponent from "../components/Header.vue";

export default {
    name: "Home",
    data() {
        return {
            url: "https://wavesmarketplace.com",
            connect: false,
            wallet: {},
            walletStatus: false,
            nfts: [],
        };
    },
    components: {
        HeaderComponent,
        ConnectWallet,
        Profile,
        Sort,
        NFT,
    },
    async mounted() {
        const data = window.localStorage.getItem("loginChoice");
        if (!data) {
            this.connect = true;
        } else {
            this.wallet = JSON.parse(data);
            this.getMyPunks(this.wallet.address);
        }
    },
    methods: {
        checkIssuer(issuer) {
            return issuer == window.contractAddress ||
                issuer == window.zombieAddress ||
                issuer == window.madEmpireAddress ||
                issuer == window.madFreeAddress ||
                issuer == window.madMutationAddress
        },
        async getMyPunks(address) {
            this.walletStatus = true;
            this.wallet.address = address;

            let nftCount = 1000;
            let after = "";

            while (nftCount === 1000) {
                await axios
                    .get(
                        `${window.nodeURL}/assets/nft/${address}/limit/1000${
                            after == "" ? "" : after
                        }`
                    )
                    .then((res) => {
                        for (let i = 0; i < res.data.length; i++) {
                            try {
                                if (this.checkIssuer(res.data[i].issuer)) {
                                    let data = {};
                                    data.name = res.data[i].name;
                                    data.assetId = res.data[i].assetId;
                                    data.metadata = JSON.parse(res.data[i].description);
                                    data.price = 0;
                                    this.nfts.push(data);
                                }
                            } catch (err) {
                                console.error(err);
                            }
                        }

                        nftCount = res.data.length;
                        after =
                            "?after=" + res.data[res.data.length - 1].assetId;
                    })
                    .catch((err) => {
                        console.error(err);
                    });
            }

            await axios
                .get(
                    `${window.nodeURL}/addresses/data/${window.marketAddress}`
                )
                .then((res) => {
                    for (let i = 0; i < res.data.length; i++) {
                        if (
                            res.data[i].key.endsWith("_owner") &&
                            res.data[i].value == address
                        ) {
                            let data = {};

                            let l = res.data[i].key.length;
                            data.assetId = res.data[i].key.substring(0, l - 6);

                            data.name = res.data.find(
                                (item) => item.key == data.assetId + "_name"
                            ).value;

                            data.metadata = JSON.parse(
                                res.data.find(
                                    (item) => item.key == data.assetId + "_description"
                                ).value
                            );

                            data.price = res.data.find(
                                (item) => item.key == data.assetId + "_price"
                            ).value / 100000000;

                            const issuer = res.data.find(
                                (item) => item.key == data.assetId + "_issuer"
                            ).value;

                            if (this.checkIssuer(issuer))
                                this.nfts.push(data);
                        }
                    }
                })
                .catch((err) => {
                    console.error(err);
                });

            this.nfts = sortLowestPrice(this.nfts);
        },
    },
};
</script>

<style scoped>
@import "../../node_modules/wavesmarketplace-app/src/assets/styles/button.css";
@import "../../node_modules/wavesmarketplace-app/src/assets/styles/nfts.css";

.wallet {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: Inter;
    font-style: normal;
}

.wallet > h2 {
    font-weight: 500;
}

.main {
    max-width: 1360px;
    margin: auto;
}

.empty {
    font-family: Inter;
    font-style: normal;
}

.empty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 357px;
    height: 500px;
    padding: 20px;
    margin: auto;
    border-radius: 18px;
}

.empty > p:first-child {
    font-size: 130px;
}

.empty > p:nth-child(2) {
    text-align: center;
}
</style>
