<template>
    <header>
        <div class="header__logo">
            <a href="/">
                <img src="/img/logo.svg" />
            </a>
        </div>
        <div class="header__links">
            <a href="#" @click="logout">LOG OUT</a>
            <a id="twitter" target="_blank" href="https://twitter.com/WavesPunks">
                <img width="46" src="/img/twitter.svg" />
            </a>
            <!-- <a id="discord" target="_blank" href="https://discord.gg/gfpKDfRtvf">
                <img src="/img/discord.svg" />
            </a> -->
            <a id="telegram" target="_blank" href="https://t.me/wavespunks_en">
                <img src="/img/telegram.svg" />
            </a>
            <!-- <a id="reddit" target="_blank" href="https://www.reddit.com/r/WavesPunks/">
                <img src="/img/reddit.svg" />
            </a> -->
            <a id="instagram" target="_blank" href="https://instagram.com/waves_punks">
                <img src="/img/instagram.svg" />
            </a>
        </div>
    </header>
</template>

<script>
export default {
    name: "Header",
    methods: {
        logout() {
            window.localStorage.removeItem("loginChoice");
            location.reload();
        },
    },
};
</script>


<style scoped>
@media only screen and (max-width: 1440px) {
    header {
        margin: 65px !important;
    }
}

@media only screen and (min-width: 769px) {
    .header__logo > p {
        margin-top: 80px;
    }
}

@media only screen and (max-width: 768px) {
    header {
        align-items: baseline !important;
    }

    .header__logo {
        display: flex;
        flex-direction: column;
    }
}

@media only screen and (min-width: 768px) {
    .header__logo {
        display: flex;
    }
}

@media only screen and (max-width: 767px) {
    header {
        flex-direction: column;
        align-items: center !important;
    }

    header > .header__links {
        margin-top: 20px;
    }

    .header__logo {
        margin-left: 60px;
    }

    .header__logo > p {
        margin-left: 20px;
    }
}

@media only screen and (max-width: 580px) {
    .header__logo {
        margin-left: 50px !important;
    }

    .header__logo > img {
        width: 200px !important;
    }

    .header__logo > p {
        margin-left: 5px !important;
    }

    .header__links > a {
        margin: 0 10px !important;
    }
}

header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    max-width: 1360px;
    margin: auto;
    margin-top: 65px;
    margin-bottom: 65px;
    font-family: Inter;
    font-style: normal;
}

.header__logo {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
}

.header__links {
    display: flex;
    align-items: center;
}

.header__links a,
.header__links a:hover,
.header__links a:active {
    text-decoration: none;
    color: black;
}

.header__links > a {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    margin: 0px 15px;
}
</style>
