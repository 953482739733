const zombie = [
    "Object eye-head",
    "Crypto Afterlife",
    "Painter Lampas",
    "DuckHead",
    "Donald mcRonald",
    "PyroFlesh",
    "Punks Mountain states",
    "Brocken Darkling",
    "Rabbit Cake",
    "Mr CyberDust",
    "MoonBoy",
    "SteelMan",
    "Jackson",
    "Emmet Sanchez",
    "Exploit",
    "Punkenstein",
    "Object X",
    "PSC Officer ",
    "Midnight king",
    "The King",
    "V H",
    "Quarter Life",
    "Nemesis Girl",
    "Merylin Monroe",
    "William's Assasinator",
    "Insane Fury",
    "Lady Vamp",
    "Greatest leader",
    "Nuclear Housewife",
    "Ms. Honky-Tonk",
    "Undead Bride",
    "Deadly Nurse",
    "Sakura Blossom",
    "Mistress of lust",
    "Lady Without Katana",
    "Miss Punkenstein",
    "Seashell Princess",
    "Spooky-Doo",
    "Temper Girl",
    "Princess Apricot",
    "NailHead",
    "Dwindle"
];

export default zombie;
